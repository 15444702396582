import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Empty } from 'antd'
import { AllGameClass, Configs, Language } from '#repositories/models/recoil-state'
import useFetchState from '#repositories/models'
import { type ListItem } from '#repositories/apis/documents'
import { type ConfigsType } from '#repositories/apis/base'
import { FreeGameCategoryId } from '#repositories/apis/dictionaries'
import { defaultLimit1, FilterPrice, SortType, type FilterPriceListType } from '#constants/appoint'
import localization, { type LT, pickLangKey } from '#utils/localization'
import { isEmpty, toNumber } from '#utils/functions'
import { scrollToTop } from '#utils/events'
import { makeProductsFilterParamsInit, makeProductsParams, parseProductsFilters, type ProductsFilterParams, Routes } from '#utils/router'
import { documentTitle } from '#utils/configs'
import Pagination from '#components/page'
import Loading from '#components/loading'
import { ProductItem } from '#components/document/item'
import Icon from '#components/icon'
import { ReactComponent as IconCategory } from '#assets/svg/category.svg'
import { ReactComponent as IconSortDesc } from '#assets/svg/sort-desc.svg'
import { ReactComponent as IconDatabaseDollar } from '#assets/svg/database-dollar.svg'
import { defCategory, fetchProducts, type MainProps } from './model'

const Main: React.FC<MainProps> = props => {
    const configs = new Configs()
    const history = useHistory()
    // 页码
    const [ page, setPage ] = useFetchState(
        props.match.params.page !== undefined && !isEmpty(props.match.params.page)
            ? toNumber(props.match.params.page, 1)
            : 1
    )
    // 每页条数
    const [ limit, setLimit ] = useFetchState(
        props.match.params.limit !== undefined && !isEmpty(props.match.params.limit)
            ? toNumber(props.match.params.limit, defaultLimit1)
            : defaultLimit1
    )
    // 分类
    const allGameClassMaps = new AllGameClass()
    const tmp = Object.values(allGameClassMaps.shared())
    tmp.sort((a, b) => b.sort - a.sort)
    const categories = [ defCategory, ...tmp ]

    const [ loading, setLoading ] = useFetchState(true)
    // language
    const lang = new Language()
    const [ langDict, setLangDict ] = useFetchState<LT>({})
    // 路由筛选参数
    const [ routerFilterParams, setRouterFilterParams ] = useFetchState<ProductsFilterParams>(makeProductsFilterParamsInit())
    const doSetRouterFilterParams = (data: ProductsFilterParams, extPage?: number): void => {
        const filters = makeProductsParams(data)
        history.push(`${ Routes.products }/${ filters === '' ? '-' : filters }/${ extPage ?? page }/${ limit }`)
        setRouterFilterParams(data)
    }
    // 列表数据
    const [ list, setList ] = useFetchState<ListItem[]>([])
    // 列表总数
    const [ total, setTotal ] = useFetchState(0)
    // 价格筛选
    const [ filterPrice, setFilterPrice ] = useFetchState<FilterPriceListType>([])

    useEffect(
        () => {
            const state = configs.state as ConfigsType
            if (state === null) {
                return
            }

            let filterPrice: FilterPriceListType = [
                {
                    val: FilterPrice.all,
                    title: langDict.all
                },
                {
                    val: FilterPrice.permanent,
                    title: langDict.permanentExclusive
                },
                {
                    val: FilterPrice.free,
                    title: langDict.freeExclusive
                },
                {
                    val: FilterPrice.paid,
                    title: langDict.paidExclusive
                }
            ]

            if (state.hideFreeGames) {
                filterPrice = filterPrice.filter(
                    item => item.val !== FilterPrice.free
                )
            }

            if (state.hideVipGames) {
                filterPrice = filterPrice.filter(
                    item => item.val !== FilterPrice.permanent
                )
            }

            setFilterPrice(filterPrice)
        },
        [ configs.state, langDict ]
    )

    useEffect(
        () => {
            scrollToTop(100)
            setRouterFilterParams(parseProductsFilters(props.match.params.filters ?? ''))
        },
        [ ]
    )

    useEffect(
        () => {
            const langDict = localization({
                lang: lang.state,
                keys: [
                    'popular',
                    'default',
                    'diskSpace',
                    'weekPopularGamesTitle',
                    'monthPopularGamesTitle',
                    'sortDesc',
                    'sortAsc',
                    'exclusive',
                    'free',
                    'vip',
                    'permanent',
                    'sitePopularGamesTitle',
                    'searchInputPlaceholder',
                    'resetTitle',
                    'allGames',
                    'categories',
                    'favorite',
                    'like',
                    'latestGroupGames',
                    'filterSortDiskSpace',
                    'filterSortRandom',
                    'filterTitlePrice',
                    'filterTitleSort',
                    'all',
                    'vipExclusive',
                    'permanentExclusive',
                    'freeExclusive',
                    'paidExclusive',
                    'downloads'
                ]
            })
            setLangDict(langDict)
            documentTitle(langDict.allGames)
        },
        [ lang.state ]
    )

    useEffect(
        () => {
            fetchProducts({
                routerFilterParams,
                setLoading,
                setList,
                setTotal,
                limit,
                page,
                hideFreeGames: configs.state !== null ? (configs.state as ConfigsType).hideFreeGames : false,
                hideVipGames: configs.state !== null ? (configs.state as ConfigsType).hideVipGames : false
            })
        },
        [ routerFilterParams, page, limit ]
    )

    return <div className="products-container">
        <div className="filter-container">
            <div className="categories-box filter-group-item">
                <p className="filter-title"><Icon className="i-3x"><IconCategory /></Icon> { langDict.categories }</p>
                <div className="categories simple">{
                    categories.filter(
                        item => !(configs.state !== null && (configs.state as ConfigsType).hideFreeGames && item.id === FreeGameCategoryId)
                    ).map(
                        (item, key) => <div
                            key={ key }
                            className={ 'category-item cursor-pointer' + (routerFilterParams.categoryId === item.id ? ' active' : '') }
                            onClick={
                                () => {
                                    doSetRouterFilterParams({
                                        ...routerFilterParams,
                                        categoryId: item.id
                                    })
                                }
                            }
                        >{ pickLangKey(lang.state, item.title) }</div>
                    )
                }</div>
            </div>
            <div className="filter-box sp">
                <div className="item filter-group-item">
                    <p className="filter-title"><Icon className="i-3x"><IconSortDesc /></Icon> { langDict.filterTitleSort }</p>
                    <div className="filter-content">{
                        Object.values(SortType).filter(item => typeof item === 'number').map(
                            (item, key) => <span
                                className={ `filter-item${ routerFilterParams.sort === item ? ' active' : '' }` }
                                key={ key }
                                onClick={
                                    () => {
                                        doSetRouterFilterParams({
                                            ...routerFilterParams,
                                            sort: item as SortType
                                        })
                                    }
                                }
                            >
                                { item === SortType.popularSite ? langDict.sitePopularGamesTitle : '' }
                                { item === SortType.favorite ? langDict.favorite : '' }
                                { item === SortType.like ? langDict.like : '' }
                                { item === SortType.updated ? langDict.latestGroupGames : '' }
                                { item === SortType.random ? langDict.filterSortRandom : '' }
                            </span>
                        )
                    }</div>
                </div>
                <div className="item filter-group-item">
                    <p className="filter-title"><Icon className="i-3x"><IconDatabaseDollar /></Icon> { langDict.filterTitlePrice }</p>
                    <div className="filter-content">{
                        filterPrice.map(
                            (item, key) => <span
                                className={ `filter-item${ routerFilterParams.exclusiveType === item.val ? ' active' : '' }` }
                                key={ key }
                                onClick={
                                    () => {
                                        doSetRouterFilterParams({
                                            ...routerFilterParams,
                                            exclusiveType: item.val
                                        })
                                    }
                                }
                            >{ item.title }</span>
                        )
                    }</div>
                </div>
            </div>
        </div>
        <div className="list-box">
            <div className="list">{
                loading
                    ? <Loading />
                    : <>{
                        list.length <= 0
                            ? <Empty description={ false } className="empty-list" />
                            : <div className="products">{
                                list.map(
                                    (item, key) => <ProductItem
                                        key={ key }
                                        item={ item }
                                    />
                                )
                            }</div>
                    }</>
            }</div>
            <Pagination
                className="pagination flex-cc"
                total={ total }
                callback={
                    (current, pageSize) => {
                        const params = makeProductsParams(routerFilterParams)
                        scrollToTop(200, () => {
                            history.push(`${
                                Routes.products
                            }/${
                                params === '' ? '-' : params
                            }/${ current }/${ pageSize }`)
                            setPage(current)
                            setLimit(pageSize)
                        })
                    }
                }
                page={ page }
                pageSize={ limit }
            />
        </div>
    </div>
}

export default Main